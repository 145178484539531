import { SliderComponent } from '@webentorCore/_slider';

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js-number-facts-slider').forEach((element) => {
    // Parse data-slider attribute to get params
    const params = JSON.parse(element.getAttribute('data-slider') || '{}');

    params.on = {
      init: () => {
        // Clone slides to fix missing next slide on change
        // https://github.com/nolimits4web/swiper/issues/7239#issuecomment-2632685384
        const slides = element.querySelectorAll('.swiper-slide');
        slides.forEach((slide) => {
          const clonedSlide = slide.cloneNode(true);
          clonedSlide?.classList.add('swiper-slide-duplicated');
          element.querySelector('.swiper-wrapper').appendChild(clonedSlide); // add the clone
        });
      },
      slideChange: (swiper) => {
        const activeSlideIndex = swiper.realIndex;

        const contentSlider = element
          .closest('.js-number-facts')
          .querySelector(
            '.js-number-facts-content-slider .js-swiper-container',
          );
        contentSlider?.swiper?.slideTo(activeSlideIndex);
      },
    };

    new SliderComponent(element, params);
  });
});
